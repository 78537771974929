import Config from 'common/config';
import { setCookie, getCookie } from 'cookies-next';
export class PrefixUrl {
  static readonly defaultUrl: string = `${Config.apiEndpoint}/api/user`;
  static readonly key = 'prefix-url';
  static isStaging() {
    return PrefixUrl.defaultUrl.includes('staging');
  }
  static get() {
    const url = getCookie(PrefixUrl.key) as string | null;
    if (PrefixUrl.isStaging()) {
      return url || PrefixUrl.defaultUrl;
    }

    return PrefixUrl.defaultUrl;
  }
  static set(url: string = PrefixUrl.defaultUrl) {
    setCookie(PrefixUrl.key, url);
    return url;
  }
}
