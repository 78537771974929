import { PrefixUrl } from './utils';

export default function PrefixUrlModifier() {
  if (PrefixUrl.isStaging()) {
    return (
      <span
        style={{
          position: 'fixed',
          opacity: 0.5,
          zIndex: 999,
          bottom: 16,
          right: 16,
        }}
        onClick={() => {
          const placeholder = PrefixUrl.get() || PrefixUrl.defaultUrl;
          const url = window.prompt(
            `Enter URL eg. ${PrefixUrl.defaultUrl}`,
            placeholder,
          );
          if (url) {
            PrefixUrl.set(url);
          }
          window.location.reload();
        }}
      >
        Staging
      </span>
    );
  }
  return null;
}
