import React from 'react';

function Icon(props: any) {
  const { size = 64, color = '#327FEB', ...rest } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size - 2}
      height={size - 2}
      fill="none"
      viewBox="0 0 64 64"
      {...rest}
    >
      <path
        fill={color}
        fillRule="evenodd"
        d="M32.004 24c5.4 0 9.777-4.378 9.777-9.778s-4.377-9.778-9.777-9.778c-5.4 0-9.778 4.378-9.778 9.778S26.603 24 32.004 24zm0 4.444c7.854 0 14.222-6.367 14.222-14.222C46.226 6.368 39.858 0 32.004 0 24.149 0 17.78 6.368 17.78 14.222c0 7.855 6.368 14.222 14.223 14.222zM3.555 64c0-1.513.118-2.997.345-4.445 2.134-13.599 13.902-24 28.1-24 14.197 0 25.965 10.401 28.098 24a28.66 28.66 0 01.346 4.444H3.554zm4.855-4.445h47.178C53.504 48.423 43.735 39.999 32 39.999c-11.736 0-21.505 8.424-23.59 19.556z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default Icon;
