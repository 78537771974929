import React from 'react';

function Icon(props: any) {
  const { size = 64, color = '#104FA6', ...rest } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 64 64"
      {...rest}
    >
      <path
        fill={color}
        fillRule="evenodd"
        d="M8.814 40.166a2.667 2.667 0 00.976 3.643l5.015 2.895a8 8 0 0113.064 7.543l5.015 2.895a2.667 2.667 0 003.643-.976l12-20.784-2.31-1.334a2.667 2.667 0 012.667-4.619l2.31 1.334 4-6.928a2.667 2.667 0 00-.977-3.643l-5.014-2.896a8 8 0 01-13.065-7.542l-5.014-2.895a2.667 2.667 0 00-3.643.976l-4 6.928 2.31 1.333a2.667 2.667 0 01-2.668 4.619l-2.309-1.333-12 20.784zM4.195 37.5L22.862 5.167A8 8 0 0133.79 2.24l6.928 4a2.667 2.667 0 01.976 3.642 2.666 2.666 0 104.62 2.667 2.667 2.667 0 013.642-.976l6.928 4a8 8 0 012.928 10.928L41.146 58.833a8 8 0 01-10.928 2.928l-6.929-4a2.667 2.667 0 01-.976-3.643 2.667 2.667 0 00-4.618-2.666 2.667 2.667 0 01-3.643.976l-6.928-4A8 8 0 014.195 37.5zm27.19-15.095a2.667 2.667 0 013.643-.976l4.619 2.667a2.667 2.667 0 01-2.667 4.619l-4.619-2.667a2.667 2.667 0 01-.976-3.642z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default Icon;
