export { default as SelectorIcon } from './selector';
export { default as TicketIcon } from './ticket';
export { default as PictureIcon } from './picture';
export { default as FileDirectoryIcon } from './file-directory';
export { default as WhatsappIcon } from './whatsapp';

export { default as UserCircleIcon } from './user';
export { default as ShippingTypeIcon } from './shipping';
// Status Icon
export { default as StatusFailedIcon } from './failed-status';
export { default as StatusSuccessIcon } from './success-status';
export { default as StatusWaitingIcon } from './waiting-status';
export { default as VoyageTransferIcon } from './voyage-transfer';

export { default as RefundIcon } from './refund';
export { default as RefundRejectedIcon } from './refund-rejected';
export { default as Ticket2Icon } from './ticket-2';
export { default as ShipDelayIcon } from './ship-delay';
export { default as MailIcon } from './mail';
export { default as PhoneCallIcon } from './phone-call';
export { default as PinPointIcon } from './pin-point';

export { default as CruiseIcon } from './cruise';
export { default as FlipCruiseIcon } from './flip-cruise';
export { default as PersonIcon } from './person';
export { default as VehicleIcon } from './vehicle';
export { default as UserSolidIcon } from './user-solid';

export { default as CruiseNotFound } from './cruise-not-found';
export { default as ExpiredTime } from './expired-time';
export { default as TicketNotFound } from './ticket-not-found';
export { default as EmptyFacility } from './empty-facility';
export { default as VehicleEmpty } from './vehicle-empty';

export { default as PageNotFoundSvg } from './not-found';
export { default as ShipDowning } from './ship-drowning';

export { default as BellRingingIcon } from './bell-ringing';

// Facilies
export { default as BedOnIcon } from './bed-on';
export { default as BedOffIcon } from './bed-off';
export { default as DoorOnIcon } from './door-on';
export { default as DoorOffIcon } from './door-off';

// Flag
export { default as IndonesiaFlag } from './indonesia-circle-flag';
export { default as UnitedKingdomFlag } from './united-kingdom-circle-flag';
// policy

export { default as PolicyIcon } from './policy-icon';

//set booking order
export { default as DownloadCircleIcon } from './circle-download';
export { default as RefundCircleIcon } from './refund-circle-icon';
export { default as RescheduleCircleIcon } from './reschedule-circle-icon';
export { default as LogoutIcon } from './logout-dialog';

export { default as CallCenterSvg } from './call-center';
export { default as NoCookieSvg } from './no-cookies';
export { default as EditSvg } from './edit-svg';
export { default as TrashSvg } from './trash-svg';
export { default as UserOffSvg } from './user-off';
export { default as PassengerSvg } from './passenger';
