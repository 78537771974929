import { getAccessToken } from 'common/credential-storage';
import { getLanguage } from 'common/language-storage';
import { PrefixUrl } from 'components/widgets/prefix-modifier/utils';
import ky, { Options } from 'ky';

const config: Options = {
  prefixUrl: PrefixUrl.isStaging() ? PrefixUrl.get() : PrefixUrl.defaultUrl,
  retry: {
    limit: 2,
    methods: ['get'],
    statusCodes: [401],
  },
  timeout: 60000,
  headers: {
    Accept: 'application/json',
  },
  hooks: {
    afterResponse: [
      async (_request, _options, res) => {
        const contentType = res.headers.get('content-type');
        let newResponse = res.clone();
        if (contentType && contentType.includes('application/json')) {
          const json = await res.json();
          const { status, statusText, headers } = res;
          newResponse = new Response(JSON.stringify(json), {
            status,
            statusText,
            headers,
          });
        }

        if (contentType && contentType.includes('application/pdf')) {
          const { status, statusText, headers } = res;
          const blob = await res.blob();

          newResponse = new Response(blob, {
            status,
            statusText,
            headers,
          });
        }

        return newResponse;
      },
    ],
    beforeRequest: [
      (request) => {
        const token = getAccessToken();
        if (token) {
          request.headers.set('Authorization', `Bearer ${token}`);
        } else {
          request.headers.delete('Authorization');
        }
        const language = getLanguage();
        request.headers.set('Accept-Language', language ?? 'id');
      },
    ],
    beforeRetry: [
      ({ request, options, error, retryCount }) => {
        request.headers.set('x-retry', retryCount.toString());
      },
    ],
  },
};

export const client = ky.create(config);
