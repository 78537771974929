import React from 'react';

function Icon(props: any) {
  const { size = 24, color = '#171C22', ...rest } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 513 513"
      {...rest}
    >
      <g clipPath="url(#clip0_3244_31498)">
        <path
          fill={color}
          d="M179.694 256.003c56.561 0 102.402-45.849 102.402-102.402 0-56.553-45.841-102.402-102.402-102.402S77.293 97.048 77.293 153.601c0 56.553 45.84 102.402 102.401 102.402zm0 38.4C38.884 294.403.5 384.245.5 460.806H363.065c0-76.561-42.56-166.403-183.371-166.403zm204.084 12.801h-76.081c44.809 48 39.368 40.66 60.809 80.001 8.721 16 20.952 65.521 15.992 73.601H512.5v-26.32c0-70.001-47.992-127.282-128.722-127.282zm-37.681-51.201c49.521 0 89.602-40.081 89.602-89.602S395.618 76.8 346.097 76.8c-20.088 0-38.432 6.844-53.377 18.008 9.216 17.696 14.977 37.513 14.977 58.793 0 28.416-9.545 54.513-25.273 75.769 16.232 16.393 38.713 26.633 63.673 26.633z"
        />
      </g>
      <defs>
        <clipPath id="clip0_3244_31498">
          <path fill="#fff" d="M0 0H512V512.009H0z" transform="translate(.5)" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Icon;
